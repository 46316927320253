// src/utils.ts
import fqs from "fast-querystring";
var cleanRecord = (record) => {
  return Object.fromEntries(
    Object.entries(record).filter(([, value]) => {
      if (value == null) {
        return false;
      }
      return value;
    })
  );
};
var qs = {
  parse: (value) => fqs.parse(value),
  stringify: (record) => {
    return fqs.stringify(cleanRecord(record));
  }
};
var makeRoute = ({
  path,
  query
}) => {
  const _query = query ? qs.stringify(query) : void 0;
  return `${path}${_query ? `?${_query}` : ""}`;
};

export {
  cleanRecord,
  qs,
  makeRoute
};
