import {
  makeRoute
} from "./chunk-ZGNZ6OJY.mjs";

// src/index.ts
var homeRoute = () => "/";
var settingsNotificationsRoute = () => "/settings/notifications";
var signInRoute = () => "/sign-in";
var signUpRoute = () => "/sign-up";
var supportRoute = () => "/support";
var privacyRoute = () => "/privacy";
var termsRoute = () => "/terms";
var unsubscribeRoute = ({ userId }) => {
  return makeRoute({
    path: "/unsubscribe",
    query: { user_id: userId }
  });
};
var matchaEssentialsListRoute = () => {
  return makeRoute({
    path: "/matcha-essentials"
  });
};
export {
  homeRoute,
  matchaEssentialsListRoute,
  privacyRoute,
  settingsNotificationsRoute,
  signInRoute,
  signUpRoute,
  supportRoute,
  termsRoute,
  unsubscribeRoute
};
